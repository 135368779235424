import { EnHeading } from 'components/atoms/enHeading';

import branding from 'assets/images/services/branding.png';
import businessDesign from 'assets/images/services/business-design.png';
import customerExperienceImprovement from 'assets/images/services/customer-experience-improvement.png';
import designOps from 'assets/images/services/design-ops.png';
import employeeExperienceDesign from 'assets/images/services/employee-experience-design.png';
import mvvDesign from 'assets/images/services/mvv-design.png';
import { JaHeading } from 'components/atoms/jaHeading';
import { Text } from 'components/atoms/text';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import FadeUpSection from 'components/organisms/fadeUpSection';
import { InquiryBlock } from 'components/organisms/inquiryBlock';
import ParticleImageManager from 'components/organisms/particleCanvas/ParticleImageManager';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { text } from 'style/mixin';
import sizes from 'style/sizes';
import { breakpoint, mq } from 'style/variables';
import { ServiceIndexQuery } from 'types/graphql-types';
import SEO from 'util/seo';

import styled from '@emotion/styled';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps {
  data: ServiceIndexQuery;
}

const TitleSection = styled('div')`
  width: 100%;
  ${mq.onlypc} {
    padding-top: ${sizes.margin[180]};
    padding-bottom: ${sizes.margin[100]};
  }
  ${mq.onlysp} {
    width: unset;
    padding-top: ${sizes.margin[155]};
    padding-bottom: ${sizes.margin[64]};
  }
`;
const Section = styled.section`
  margin-bottom: 180px;

  ${mq.onlysp} {
    margin-bottom: 120px;
  }
`;
const InfoBoxSection = styled.div`
  display: block;
`;
const InfoBox = styled.div`
  position: relative;

  ${mq.onlypc} {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto 1fr;
    align-items: start;
  }

  & + & {
    margin-top: ${sizes.margin['160']};

    ${mq.onlysp} {
      margin-top: 110px;
    }
  }
`;
const InfoBoxCategory = styled(EnHeading)`
  grid-column: 1 / 2;
  ${text(56, 1)}
  margin-bottom: 26px;
  letter-spacing: -0.02em;
  ${mq.onlysp} {
    margin-bottom: 18px;
  }
`;
const InfoBoxHeader = styled(JaHeading)`
  grid-column: 1 / 2;
  ${text(28, 1.5)}
  font-weight: 600;
  letter-spacing: 0.09em;
  ${mq.onlysp} {
    ${text(18, 1.5)}
    font-weight: 600;
  }
`;
const InfoBoxIcon = styled.div`
  grid-column: 2 / 3;
  grid-row: 1 / 4;
  display: flex;
  justify-content: flex-end;
  align-self: center;
  img {
    width: 330px;
    height: auto;
    object-fit: cover;
    display: block;

    ${mq.onlysp} {
      width: 180px;
    }
  }

  ${mq.onlypc} {
    min-width: 330px;
  }
  ${mq.onlysp} {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: 33px;
  }
`;
const InfoBoxContent = styled(Text)`
  grid-column: 1 / 2;
  grid-row: 3;
  margin-top: 50px;
  ${mq.onlypc} {
    ${text(18, 2.12)}
    width: 688px;
  }
  ${mq.onlysp} {
    ${text(16, 1.8)}
    margin-left: 33px;
  }
`;

const infoBoxData: Array<{
  category: string | React.ReactNode;
  header: React.ReactNode;
  icon: string;
  content: string;
}> = [
  {
    category: 'Business Design',
    header: <>Design new experiences centered around user understanding, shaping the value proposition of businesses</>,
    icon: businessDesign,
    content: `We paint an overall picture of the user experience, generating ideas for features and measures that could be implemented without being bound by the constraints of existing structures. We accomplish these via research to obtain factual data. Taking into account the feasibility from both business and technical perspectives, we assist in formulating roadmaps and conceptualizing architectures for services and products.`,
  },
  {
    category: (
      <>
        Customer Experience
        <br />
        Improvement
      </>
    ),
    header: <>Clarify the usage of existing services and implement improvements toward the ideal state</>,
    icon: customerExperienceImprovement,
    content: `Through user research and analysis of usage data, we bridge the gap between user needs and their experiences in existing services, redefining the user experience. We identify necessary functions throughout the entire process, from users’ awareness of the service to its usage along with post-release support. We formulate development structures and plans for continuous improvement of the service through post-release analysis.`,
  },
  {
    category: 'MVV Design',
    header: <>Identify the strengths of services and businesses, and define the direction to pursue</>,
    icon: mvvDesign,
    content: `We define the vision of companies and businesses, formulating concepts that will serve as a foundation for future initiatives and new operations. In addition to providing guidance for business development decisions and employee work ethics, we plan and implement measures to communicate the business direction to stakeholders both inside and outside the organization.`,
  },
  {
    category: (
      <>
        Employee
        <br />
        Experience Design
      </>
    ),
    header: (
      <>Understand user interactions, business processes and company culture to define a better employee experience</>
    ),
    icon: employeeExperienceDesign,
    content: `We conduct multifaceted research on both existing operations and the people involved to define the ideal employee experience, taking into account not only the productivity aspects but also the psychological aspects of working in an organization. We support the UI/UX development for operational systems and design work environments to improve the employee experience and enable employees to yield results independently.`,
  },
  {
    category: 'Branding',
    header: <>Shape the brand's identity and values to foster connections with users</>,
    icon: branding,
    content: `We design communication strategies that accurately convey the brand value by reflecting the characteristics of the company, product or service, as well as grasping target customers and users. We provide services ranging from formulating brand concepts to designing logos, visual identities, design systems and content design.`,
  },
  {
    category: 'Design Ops',
    header: <>Support business growth through continuous service improvement</>,
    icon: designOps,
    content: `We will be the driving force behind continuous service operations and commit to medium and long-term business growth. Not only that, We will also help you plan and implement an improvement cycle that includes analyzing service usage, creating improvement measures from the user's perspective, implementing functions, and verifying its effectiveness, as well as building the structure and system necessary to achieve this.`,
  },
];

const Pages: React.FC<IndexPageProps> = () => {
  let isSp = false;
  if (typeof window !== 'undefined') isSp = window.innerWidth <= breakpoint;

  useEffect(() => {
    ParticleImageManager.loadAll().then(() => {
      ParticleImageManager.generateAll().then(() => {
        ParticleImageManager.start();
      });
    });

    return () => {
      ParticleImageManager.delete();
    };
  }, []);

  return (
    <>
      <SEO title={'Services'} en />
      <ContentWrapper>
        <TitleSection>
          <EnHeading>Services</EnHeading>
        </TitleSection>
        <Section>
          <FadeUpSection>
            <InfoBoxSection>
              {infoBoxData.map((info, idx) => (
                <InfoBox key={idx}>
                  <InfoBoxCategory>{info.category}</InfoBoxCategory>
                  <InfoBoxHeader>{info.header}</InfoBoxHeader>
                  <InfoBoxContent>{info.content}</InfoBoxContent>
                  <InfoBoxIcon>
                    <img src={info.icon} alt="" />
                  </InfoBoxIcon>
                </InfoBox>
              ))}
            </InfoBoxSection>
          </FadeUpSection>
        </Section>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export const pageQuery = graphql`
  query ServiceIndexEn {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default Pages;
